.wrapper{
    /* border: 1px solid red; */
}
.sub_head_style{
    color:var(--banner-desk-fontColor);
    font-family: 'Poppins', sans-serif;
    font-size: var(--sub-heading-fontSize);
    font-style: normal;
    font-weight: 500;
    line-height: 36px; 
}
.text_wrapper{
    padding-bottom: 2.5rem;
}
.image_wrapper{
    margin-bottom: 6.563rem;
}

@media (max-width:540px) {

     .sub_head_style{
  font-size: 1.25rem;
  text-align: center;
  
  }
}