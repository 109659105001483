.wrapper {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 3rem 1.5rem;
    background-color: var(--white-color);
    border: "1px solid red";
    height: auto; 
    border-radius:1rem;
    text-align: center;
    /* border: 1px solid red; */
    height: 100%;
}

.child {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: auto; 
    /* align-items: center; */
}

.content_Text {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #293A41;
}

.percentage {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
}

.circleProgress {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width:1135px) {
    .wrapper {
        padding: 28px 15px;
    }

    /* .content_Text {
          
            font-size: .875rem;
    
        } */
    .percentage {
        font-size: 1.5rem;
        font-weight: 500;
    }
}

@media(max-width:938px) {
    .child {
        display: flex;
        flex-direction: column;
    }
}

@media(max-width:550px) {
   
}

@media (max-width:426px) {
    .content_Text {
        font-size: 0.875rem;
    }

}