.benBasicDetail_wrapper {
    width: 90%;
    margin: auto;
    /* border: 1px solid red; */
    margin-top: 20px;
    padding: 5px;
}


.benBasicDetail_container {
    /* border: 1px solid rgb(0, 255, 34); */
    height: 100%;
}

.benBasicDetail_Section {
    display: grid;
    grid-template-columns: 48% 50%;
    justify-content: space-between;
    height: 100%;
}

.ben_banner_image {
    width: 100%;

}

.ben_banner_image img {
    border-radius: 20px;
    /* height: 100%; */
    /* border: 1px solid red; */
}

.heading {
    font-size: 1.55rem;
    color: #1C2B2F;
    font-weight: 500;
    margin-bottom: 10px;
}

.benAddForm {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: .975rem;
    border-radius: .575rem;
}

.benMinDetail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

@media(max-width:1110px) {
    .ben_banner_image {
        display: none;
    }

    .benBasicDetail_Section {

        grid-template-columns: 100%;

    }
}

@media(max-width:660px) {
    .ben_banner_image {
        display: none;
    }

    .benMinDetail {

        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }

}