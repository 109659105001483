.ben_wrapper {
    width: 90%;
    margin: auto;
    /* height: 500px; */
    /* border: 1px solid red; */
    margin-top: 20px;
    padding: 5px;
}

.ben_container {
    /* border: 1px solid rgb(0, 255, 34); */
    height: 100%;
}

.ben_section {
    display: grid;
    grid-template-columns: 48% 51%;
    justify-content: space-between;
    height: 100%;
}

.ben_section div {
    /* border: 5px solid rgb(0, 42, 255); */
}

.ben_banner_image {
    width: 100%;
    height: 100%;
}

.ben_banner_image img {
    border-radius: 20px;
    height: 100%;
    /* border: 1px solid red; */
}

.heading {
    font-size: 1.55rem;
    color: #1C2B2F;
    font-weight: 500;
}

.benAddForm {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: .975rem;
    border-radius: .575rem;
}

.formData {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    gap: .575rem;
}

.addChild {
    display: flex;
    align-items: center;
    gap: .875rem;

}

.titleStyle {
    color: #008470;
    font-weight: 500;
    cursor: pointer;
}

.referenceStyle {
    font-weight: 500;
}

@media(max-width:950px) {
    .formData {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(max-width:850px) {
    .formData {
        grid-template-columns: repeat(1, 1fr);
    }

    .ben_banner_image {
        display: none;

    }

    .ben_section {
        display: grid;
        grid-template-columns: 95%;
        justify-content: center;

    }

    .heading {
        font-size: 1rem;
        color: #1C2B2F;
        font-weight: 500;
    }

}