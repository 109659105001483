.mainCard {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #cecece;
  border-radius: 10px;
  margin-bottom: 20px;
}

.icon {
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  background-color: #008470;
  color: #cecece;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.controllerIcon {
  font-size: 40px;
  padding: 10px;
  border-radius: 50px;
  background-color: #cecece;
  color: black;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}


@media only screen and (max-width: 565px) {
  .mainCard{
    display: block;
    justify-content: center;
  }

}
