.main {
  padding-top: 30px;
  height: 100%;
  background-color: #eef2f2;
  padding-bottom: 30px;
  margin-top: 5rem;
}


.header {
  background-color: white;
  width: 80%;
  margin: auto;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.header div,
.header div p,
.header div h3 {
  background-color: white;
}

.header img {
  top: 168px;
  left: 166px;
  width: 198px;
  height: 118px;
}

.header div > h3 {
  font-weight: 700;
  font-size: 20px;
}

.header div > p {
  color: #3b5753;
}

.benHeading {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.benificiaryList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  gap: 20px;
}
.floatingBtn {
  display: none;
}

/* qr modal */

.qr_code {
  width: 390px;
  height: 390px;
}

/* //Qr_modal */
.qr_container {
  border: 1px solid red;
  margin-top: -1rem;
}

.poco_Logo_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid transparent !important;
  border-image: linear-gradient(
    0.25turn,
    rgba(255, 249, 34),
    rgba(255, 0, 128),
    rgba(56, 2, 155, 0)
  );
  border-image-slice: 1;
}

.bottom_Border {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid red;
}

.poco_Logo img {
  width: 70px;
  height: 70px;
}

.qr_Code {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgba(255, 249, 34),
    rgba(255, 0, 128),
    rgba(56, 2, 155, 0)
  );
  border-image-slice: 1;
}

.scanner_Text {
  text-align: center;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgba(255, 249, 34),
    rgba(255, 0, 128),
    rgba(56, 2, 155, 0)
  );
  border-image-slice: 1;
}

.text_Style {
  margin-top: 0.55rem;
  margin-bottom: 0.55rem;
  color: #008470;
  font-size: 1rem;
  font-weight: 600;
}

.phone_Number {
  font-size: 1rem;
  font-weight: 600;
  color: rgba(255, 0, 128);
}

.center_PhoneNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* FloatingButton.css */

.floatingbutton {
  position: fixed;
  bottom: 30px;
  right: 60px; 
  background-color: white; /* Button background color */
  color: #008470; /* Button text color */
  border: none;
  border-radius: 50%; /* Makes the button circular */
  width: 60px; /* Adjust the button's width as needed */
  height: 60px; /* Adjust the button's height as needed */
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.myPhoneInputClass {
  height: 100% !important;
  width: 100% !important;
}

.myContainerClass {
  height: 40px;
}



@media only screen and (max-width: 1000px) {
  .header {
    display: flex;
    flex-direction: column;
  }
  .benHeading {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .benificiaryList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    gap: 20px;
  }
  .floatingBtn {
    display: block;
  }
}

@media only screen and (max-width: 700px) {
  .header {
    display: flex;
    flex-direction: column;
  }
  .benHeading {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .benificiaryList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    gap: 20px;
  }
  .floatingBtn {
    display: block;
  }
}


