.wrapper {
    background-color: white;
    height: 100%;
    padding-bottom: 1rem;
    margin-bottom: -1rem;
}

.container {
    width: 50%;
    margin: auto;
    padding-top: 5rem;
    margin-bottom: 2rem;
}

.text_Style {
    font-family: "Poppins" ;
    margin-top: 1.5rem;
    font-size: 1.2rem;
    line-height: 2.3rem;
    color: rgb(23, 27, 35)
}

.mission_section {
    height: 250px;
    background-color: rgb(243, 109, 180);
}

.mission_container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section_text {
    font-size: 2rem;
    color: white;
    font-weight: 600;
    font-family: "Poppins" ;
}

.section_text_2 {
    font-size: 1.2rem;
    color: white;
    font-weight: 600;
    font-family: "Poppins" ;
}

.vision_section {
    width: 100%;
    background: rgb(215, 251, 247);
    /* border: 5px solid red; */

}

.vision_container {
    width: 100%;


}

.center_Text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid_System {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    gap: 2.5rem;

}

.screen_visible {
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
}

.image_wrapper {

    display: flex;
    align-items: center;
    justify-content: center;
}

.image_wrapper img {


    width: 80px;
    height: 80px;
}

.team_Wrapper,
.opening_Wrapper {
    width: 100%;
    background: white;
    padding-bottom: 1rem;

}

.section_text_Values {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Poppins" ;

}

.opening_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.border {
    border-bottom: 2px solid rgb(130, 125, 125);
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.jobName {
    font-size: 1.3rem;
    color: var(--primary-color);

}

.location {
    font-size: 1.3rem;
    color: var(--primary-color);
}

.opening_warper {
    width: 80%;
    margin: auto;
}

@media(max-width:768px) {
    .container {
        width: 80%;

    }

    .grid_System {
        grid-template-columns: repeat(2, 1fr);
    }

    .text_Style {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

@media (max-width:450px) {
    .container {
        width: 90%;

    }
}

@media (max-width:400px) {
    .grid_System {
        grid-template-columns: repeat(1, 1fr);
    }

    .section_text_2 {
        text-align: center;
    }
}