.main {
  padding-top: 30px;
  height: auto;
  /* border: 1px solid red; */
    background-color: #eef2f2;
  margin-top: 5rem;
  height: 100vh;
}

.benDetails {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  display: flex;
}

.left {
  background-color: #f5f9f9;
  padding: 30px;
  width: 35%;
  height: 400;
}

.iconBgGreen {
  background-color: #008470;
  padding: 10px;
  border-radius: 100px;
  font-size: 45px;
  color: white;
}

.iconBgGray {
  background-color: #eff3f3;
  padding: 10px;
  border-radius: 100px;
  font-size: 45px;
  color: black;
}

.completed {
  background-color: #d4eeea;
  padding: 10px;
  border-radius: 100px;
  font-size: 45px;
  color: #008470;
}

.textGreen {
  color: #008470;
  font-weight: bold;
}

.textBlack {
  color: black;
}

.right {
  background-color: white;
  height: 650px;
  padding: 30px;
  width: 70%;
  margin: auto;
  align-items: center;
  overflow-y: scroll;
  /* overflow-x: hidden; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.left {
  display: block;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 750px) {
  .left {
    display: none;
  }

  .right {
    width: 100%;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-y: scroll;
   
  }
}
