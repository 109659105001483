.personalDetails_ChildMobile {
    display: none;
}

.profile_wrapper {
    height: 100vh;
    width: 90%;
    margin: auto;
}

.profile_sub {
    display: flex;
    justify-content: space-between;
    /* height: 100vh; */
}

.profile_left {
    width: 20%;
    border: 2px solid green;
    height: 100%;
}

.profile_right {
    position: relative;
    width: 75%;
    /* border: 2px solid rgb(0, 15, 128); */

    /* height: 100%; */
}

.left_list {
    cursor: pointer;
}

.left_list li {
    color: #1c2b2f;
    font-size: 1rem;
    font-weight: 500;
}

.ben_PersonalDetails {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;
    width: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.personalDetails_Bottom {
    /* border: 2px solid rgb(128, 0, 105); */

    margin-top: 0.75rem;
}

.personalDetails_Top {
    background: transparent linear-gradient(98deg, #e1fffa 0%, #ffe1f1 50%, #e1fffa 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 5px;
}

.personDetails_Child {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    /* background: transparent
    linear-gradient(98deg, #e1fffa 0%, #ffe1f1 50%, #e1fffa 100%) 0% 0%
    no-repeat padding-box; */
    border-radius: 0px;
    /* border: 3px solid red; */
    padding: 0.55rem;
}

.nameContainer {
    padding-left: 0rem;
    margin-top: 0.25rem;
}

.personDetails_left {
    width: 50%;
    /* border: 3px solid rgb(8, 0, 255); */
    display: flex;
    flex-direction: column;
}

.personDetails_right {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    /* border: 3px solid rgb(8, 0, 255); */
}

.personDetails_right_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    border-radius: 50%;
    height: 115px;
    position: relative;
    margin-left: -1rem;
    /* border: 1px solid red; */
}

.benName_center {
    display: flex;
    color: #008470;
    height: 100%;
    background-color: #dac2c2;
    font-weight: 500;
    font-size: xx-large;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.personDetails_right_img img {
    height: 100%;
    max-width: 100%;
    border-radius: 50%;
}

.circleProgress {
    position: absolute;
    top: 0px;
}

.personalDetails_RightDet {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.benName {
    color: #1c2b2f;
    font-weight: 600;
}

.relationToBen {
    /* color: #6f6f6f; */
    font-size: 20px;
}

.requestDetail {
    background: #eff3f3 0% 0% no-repeat padding-box;
    border-radius: 40px;
    cursor: pointer;
    padding: 8px;
    opacity: 1;
    height: 36px;
    color: #008470;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border: 1px solid #008470;
    text-transform: uppercase;
    position: relative;
}

.customercareImage {
    width: 36px;
    border: 1px solid #008470;
    cursor: pointer;
    height: 36px;
    background: #eff3f3 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customercareImage img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ambulanceImage {
    width: 36px;
    height: 36px;
    background: #fcd3d0 0% 0% no-repeat padding-box;
    border-radius: 40px;
    cursor: pointer;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ambulanceImage img {
    width: 20px;
    height: 20px;
}

.edit {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
}

.topColor {
    position: absolute;
    background: transparent linear-gradient(98deg, #e1fffa 0%, #ffe1f1 50%, #e1fffa 100%) 0% 0% no-repeat padding-box;
    height: 100px;
    top: 0;
    width: 100%;
    z-index: -1;
}

.profileDetails_Bot_child {
    display: flex;
    justify-content: space-evenly;
    /* border: 1px solid red; */
}

.subTitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: #008470;
}

.profileDetails_Bot_left {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #008470; */
}

.profileDetails_Bot_right {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}

.contactDetails_Right_det {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
}

.contact_Number {
    /* color: #1c2b2f; */
    font-size: 18px;
}

.pendingDetails {
    display: flex;
    margin-top: 20px;
    /* gap: 100px; */
    padding-left: 13px;
    justify-content: space-between;
    align-items: flex-start;
    /* border: 1px solid red; */
}

.pendingDetail_Title {
    color: #008470;
    font-weight: 600;
}

.penDetails_det {
    color: #1c2b2f;
    font-size: 18px;
    font-weight: 450;
}

.additionalDetails {
    padding-left: 0.875rem;
    color: var(--primary-color);
    font-weight: 500;
    margin-top: 0.875rem;
}

.addDetails {
    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.detailsCard {
    display: flex;
    align-items: center;
}

.responsive_modal {
    max-width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.qr_code {
    width: 390px;
    height: 390px;
}

/* //Qr_modal */
.qr_container {
    border: 1px solid red;
    margin-top: -1rem;
}

.poco_Logo_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid transparent !important;
    border-image: linear-gradient(0.25turn,
            rgba(255, 249, 34),
            rgba(255, 0, 128),
            rgba(56, 2, 155, 0));
    border-image-slice: 1;
}

.bottom_Border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid red;
}

/* .poco_Logo {
  width: 160px;
  height: 55px;
  margin-bottom: 0.55rem;
} */

.poco_Logo img {
    width: 70px;
    height: 70px;
}

.qr_Code {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn,
            rgba(255, 249, 34),
            rgba(255, 0, 128),
            rgba(56, 2, 155, 0));
    border-image-slice: 1;
}

.scanner_Text {
    text-align: center;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn,
            rgba(255, 249, 34),
            rgba(255, 0, 128),
            rgba(56, 2, 155, 0));
    border-image-slice: 1;
}

.text_Style {
    margin-top: 0.55rem;
    margin-bottom: 0.55rem;
    color: #008470;
    font-size: 1rem;
    font-weight: 600;
}

.phone_Number {
    font-size: 1rem;
    font-weight: 600;
    color: rgba(255, 0, 128);
}

.center_PhoneNumber {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .responsive_modal {
        max-width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media (max-width: 1205px) {
    .contactDetails_Right_det {
        display: block;
    }
}

@media (max-width: 768px) {
    .contactDetails_Right_det {
        display: block;
        gap: 5px;
    }
}

@media (max-width: 625px) {
    .contactDetails_Right_det {
        display: block;
    }
}

@media (max-width: 600px) {
    .profileDetails_Bot_child {
        display: flex;
        padding-left: 5px;
        gap: 5px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .profileDetails_Bot_right {
        display: block;
    }

    .profileDetails_Bot_left {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .pendingDetails {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
        margin-top: 5px;
        padding-left: 5px;
    }

    .responsive_modal {
        max-width: 90%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid red;
    }

    .qr_code {
        width: 500px;
        height: 500px;
    }

    .personDetails_right_img {
        width: 115px;
        border-radius: 50%;
        height: 115px;
    }
}

@media (max-width: 768px) {
    .profile_left {
        display: none;
    }

    .profile_right {
        position: relative;
        width: 100%;
        /* border: 2px solid rgb(0, 15, 128); */
    }
}

@media (max-width: 380px) {
    .pendingDetails {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
        margin-top: 5px;
        padding-left: 5px;
    }

    .personalDetails_RightDet {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
    }

    .edit {
        top: 3px;
    }

    .icon_Remove {
        display: none;
    }

    .personDetails_Child {
        display: none;
    }

    .personalDetails_ChildMobile {
        display: block
    }
}