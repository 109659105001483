.medical_wrapper {

    position: relative;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 20px;
    opacity: 1;

}

.withoutBorder {
    background: none;
}

.medical_container {
    padding: 20px;
}

.subTitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: #008470;
}

.hoverEffect:hover {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    opacity: 1;
}

.editIcon {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
    cursor: pointer;

}

.addMedicalDet {
    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.medicalCard {
    display: flex;
    align-items: center;
}