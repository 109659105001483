.main {
    width: 100%;
    height: 100vh;
    margin: auto;
    background-color: white;
  }
  
.container {
    width: 80%;
    margin: auto;
  
    padding-top: 30px;
}
  