.navbar {
    background-color:var(--white-color);
    height: 5.313rem;
    position: fixed;
    z-index: 1000;
    width: 100%;
    /* border: 1px solid red; */
    top: 0;
}

.hr {
    border: none;
    height: 2px;
    background: #cecece;
}

.icons {
    font-size: 30px;
    background-color: white;
    margin: 15px;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
}

.link_Style {
    color: var(--nav-text-style);
    font-weight: 400;
    font-family: "Poppins";
    font-size: var(--nav-fontSize);
    height: 100%;
    cursor: pointer;
    /* width: 100%;   */
    /* Add a smooth transition for the background */
    border-bottom: 1px solid  #5B6C78; 
    /* Add padding to the links */
}
.link_Style_opacity{
    color: var(--nav-text-style);
    font-weight: 400;
    font-family: "Poppins" ;
    font-size: var(--nav-fontSize);
    height: 100%;
    /* width: 100%;   */
    /* Add a smooth transition for the background */
     opacity: 0.6;
     cursor: pointer;
}

.selected {
    color: #ef3a9a !important;
    font-weight: 600;
}

.selected:hover {
    color: black;
    font-weight: 600;
}
.button_Style{
    background-color: var(--primary-color);
    font-family: "Poppins" sans-serif;
    color: var(--white-color);
    padding: 0.75rem 1.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
}
/* .button_Style:hover{
    background-color: var(--primary-color);
} */

.navAlign{
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 30%;
}
.leftNavAlign{
    justify-content: space-around;
    align-items: center;
    width: 30%;
}

@media(max-width:540px){
    .button_Style{
    
        font-size: .8rem;
        
    }  
}