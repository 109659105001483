:root {
  /* --primary-color: #E961AB; */
  --primary-color: #00A7B5;
  --secondary-color: #293A41;
  --secondary-bacground: #F5F5F5;
  --nav-text-style: #5B6C78;
  --banner-desk-fontColor: #030303;
  --circle-backgroundColor: #293A41;
  --circle-unSelected_backgroundColor: #C4C8CB;
  --banner-primary-fontSize-desk: 2.25rem;
  --banner-secondary-fontSize-desk: 2.375rem;
  --banner-primary-fontSize-mobile: 1.875rem;
  --banner-secondary-fontSize-mobile: 1.25rem;
  --white-color: #FFFFFF;
  --sub-heading-fontSize: 2.5rem;
  --nav-fontSize: 1rem;
  --heading-fontSize: 2.5rem;
  --sub-heading-fontSize: 1.5rem;
  --light-gray: #E1E8EB;
  --pink: #E961AB;
  --green: #40DABA;
  --deep-grey: #5B6C78;
  --banner-width: 100%;
  --heading-Color: #3C3D3F;
}