.wrapper {

    background: #FFF;
    box-shadow: 2px -4px 15.1px 0px rgba(0, 0, 0, 0.09);
    border-radius: 1rem;
    padding: 2rem;
    width: 100%; /* Set width to 100% */
    max-width: 40rem; /* Set a max-width to limit the width */
    margin: 0 auto; 
}

.button {
    width: 70%;
    padding: .7rem;
    border-radius:.5rem;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.top_text {
    color: var(--primary-color);
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

.heading_text {
    color: #293A41;
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.strike_word{
    font-family: 'Poppins', sans-serif;
    text-decoration: line-through;
  position: relative;
  color: rgba(41, 58, 65, 0.40);
  font-weight: 400;
  font-size: 1.125rem;
}
.new_word{
    position: absolute;
    font-family: 'Poppins', sans-serif;
    top: -13px;
    left: -2px;
    color:var(--primary-color);
    font-size: 0.875rem;
  font-weight: 400;
}

.bottom_text {
    color:  #5B6C78;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
}

.contactUs_Card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

@media(max-width:700px) {
    .contactUs_Card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 1rem;

    }
}