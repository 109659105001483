.css-1sjamm5 {
    /* border: 1px solid #39a593 !important; */
    height: 80px !important;

}

.css-1sjamm5 input {
    border: 1px solid #39a593 !important;

}

.css-1sjamm5 {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

/* .chakra-modal__body {
    background: transparent linear-gradient(159deg, #E1FFFA 0%, #FFE1F1 50%, #E1FFFA 100%) 0% 0% no-repeat padding-box;
} */