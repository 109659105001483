.myPhoneInputClass {
  height: "57px" !important;
  margin-bottom: 30px;
  width: 60% !important;
}

.greenIcon {
  font-size: 40px;
  padding: 10px;
  border-radius: 50px;
  background-color: #cecece;
  color: black;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.profilePictureInput {
  width: 60%;
}

.otherInput {
  width: 60%;
}

.topInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.topInputField {
  width: 60%;
}
.profilePicContainer {
  display: flex;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .topInput {
    flex-direction: column-reverse;
    width: 100%;
    margin-bottom: 30px;
  }

  .topInputField {
    width: 100%;
  }

  .myPhoneInputClass {
    height: "65px" !important;
    width: 100% !important;
  }

  .otherInput {
    width: 100%;
  }
}
