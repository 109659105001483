.hospital_wrapper {

    position: relative;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 20px;

}

.hospital_container {
    padding: 20px;
    /* border: 1px solid red; */

}

.subTitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: #008470;
}

.editIcon {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;

}

.typeHospital {
    color: #1C2B2F;
    font-size: 1.125rem;
    font-weight: 500;
}

.hospitals_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 1rem;

}

.left {
    width: 45%;

    margin-top: 1rem;
}

.right {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addHospitalDet {
    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.hospitalCard {
    display: flex;
    align-items: center;
}



@media(max-width:590px) {
    .hospitals_details {
        display: flex;
    }

    .left {
        width: 100%;
    }

    .right {
        justify-content: flex-start;
    }
}

@media(max-width:450px) {
    .hospitals_details {
        display: flex;
        flex-wrap: wrap;

    }

    .left {
        width: 100%;
    }


}