.wrapper{
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;


}
.flex_Box{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   

}
.text{

    color:var(--nav-text-style);
    font-family: 'Poppins', sans-serif;
    font-size: var(--nav-fontSize);
    font-weight: 400;
    line-height: 30px;
    font-style: normal;
    text-align: center;
}
.heading{
    display: flex;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    color: green;
}