.contactCardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: "20px";
}

.redIcon,
.greenIcon {
  font-size: 40px;
  padding: 10px;
  border-radius: 50px;
  background-color: #cecece;
  color: black;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.redIcon:active,
.greenIcon:active {
  background-color: #cecece;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transform: translateY(4px);
}

.myPhoneInputClass {
  height: "57px" !important;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .contactCardContainer {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    margin: auto;
    gap: "20px";
  }
}
