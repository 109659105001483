.emergencyWrapper {
    /* border: 1px solid red; */
    position: relative;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 20px;
}

.profileDetails_Bot_child {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    justify-content: space-between;
    /* border: 1px solid red; */
    margin-bottom: 5px;

}

.emergency_container {
    padding: 1.25rem;
}

.emergencyDetails {
    border: 1px solid red
}

.subTitle {

    font-size: 1.1rem;
    font-weight: 600;
    color: #008470;

}

.Title {

    font-size: 1.1rem;
    font-weight: 500;


}

.profileDetails_Bot_left {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.profileDetails_Bot_right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    padding-right: 1rem;
    /* border: 1px solid red; */
}

.contactDetails_Right_det {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
}

.contact_Number {
    color: #1C2B2F;
}

.isPrimary {
    background: #F68524 0% 0% no-repeat padding-box;
    border-radius: 60px;
    opacity: 1;
    width: 6rem;
    height: 1.5rem;
    color: #1C2B2F;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

}

.editIcon {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}

.hideDisplay {
    display: none;
}

.showDisplay {
    display: block;
}

.addEmergencyDet {

    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.emergencyCard {
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
}

@media (max-width: 768px) {
    .contactDetails_Right_det {
        display: block;
        gap: 5px;
    }
}

@media (max-width: 625px) {
    .contactDetails_Right_det {
        display: block;
    }
}

@media(max-width:600px) {
    .profileDetails_Bot_child {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* padding-left: 15px; */
        /* border: 1px solid red; */

    }

    .profileDetails_Bot_right {
        display: block;
    }

    .profileDetails_Bot_left {
        width: 100%;
    }
}