.text_Style {
    color: var(--white-color);
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text_Child {
    color: var(--white-color);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer_grid {
    width: 84%;
    display: flex;
   justify-content: space-between;
   margin: auto;
    /* border: 1px solid red; */
    margin-top: 3rem;
}

.text_style{
color: var(--pure-white, #FFF);
font-family: "Poppins", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-transform: capitalize;
cursor: pointer;
}

.Copyright_style{
          color: #fff;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        width: 100%;
        font-weight: 400;
      }

      .footer_logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 344px;
        height: 124px;
        
      }
  
      .icon_style{
        width: 15px;
        height: 20px;
      }

@media(max-width:768px) {

    .footer_grid {
        display: flex;
        justify-content: center;
        align-items: center; 
        /* display: none; */
        margin-top: 2rem;

    }
    .footer_logo{
             
        /* border: 1px solid red; */
        
      }

    .footer_grid_small {
        display: flex;
        justify-content: center;
    }

    .footer_grid_small2 {
        display: flex;
        justify-content: space-around;
        margin-top: 1rem;
        margin-bottom: 1rem;

    }
}

@media(max-width:540px) {
    .logo_Width{
        width: 25px;
        height: auto;
    }

    /* .footer_grid {
        display: none;

    } */
    .footer_logo{
             
      width: 156px;
      height: 60px;
        
      }
      .icon_style{
        width: 12px;
        height: 12px;
      }

    .text_Child,
    .text_Style {

        font-size: 12px;

    }
    .text_style{
        font-size: 12px; 
    }

    .image_Prop {
        width: 0.45rem;
        height: 0.563rem;
    }

    .social_icon {
        width: 1.25rem;
        height: 1.25rem;
    }
    .Copyright_style{
        font-size: 12px;
        width: 100%;
      
    }

}