.abhaIntegration {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 10px;
    margin: auto;
}
.controllerIcon {
    font-size: 40px;
    padding: 10px;
    border-radius: 50px;
    background-color: #cecece;
    color: black;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
@media only screen and (max-width: 600px) {
    .abhaIntegration {
        flex-direction: column;
        margin: 1px;
    }
    .LinkAndCreate {
       margin: 4px;
    }
  }