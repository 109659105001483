.wrapper{
    /* border: 1px solid red; */
    /* height: 450px; */
    
}
.list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
    height: 100%;
    
}

.flex_List{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 4rem;
    height: 100%;
    position: relative;
}
.circle_selected{
    width:3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--circle-backgroundColor);
    font-weight: 600;
    color: #FFFFFF;
    border-radius: 50%;
    margin-left: -.3rem;
}
.circle_unselcted{
    width: 2.875rem;
    height: 2.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--circle-unSelected_backgroundColor);
    color: #FFFFFF;
    border-radius: 50%;
}
.text_Unselected{
    font-size: 1.2rem;
    color: #5B6C78;

    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    
    /* word-wrap: break-word; */
}

.text_Selected{
    color: #293A41;
    font-size: 1.2rem;
    font-weight: 500;    
    font-family: 'Poppins', sans-serif;
}
.steps_width{
    width: 20%;
}


@media(max-width:540px){
    .text_Selected{
        font-size: 1rem;
    }
    .text_Unselected{
        font-size: 1rem;
    }
    .steps_width{
        width: 20%;
    }
    .flex_List{
        gap: 1rem;
    }
}