.wrapper{
    /* border: 1px solid red; */
}
.flex_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.flex_list{
    width: 100%;
}
/* .image_wrapper{
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.text_wrapper{    
    color:var(--nav-text-style);
    font-family: 'Poppins', sans-serif;
    font-size: var(--nav-fontSize);
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    word-wrap: break-word;
}

@media (max-width:540px){
  .text_wrapper{
    font-size: .875rem;
  }  
  .flex_list{
    gap: 1rem;
  }
 
}