.wrapper {
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  /* height: 100%; */
}

.container{
  border-radius: 16px;
  background: #fff;
  box-shadow: 2px 4px 15.1px 0px rgba(0, 0, 0, 0.09);
 padding-bottom: 1.2rem;
 /* border: 1px solid red; */
 height: 100%;

}
.card_flex{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card_container{
    /* border: 1px solid green; */
}
.card_heading_style{
color: var(--nav-text-style);
font-family: 'Poppins', sans-serif;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 36px; /* 180% */
}
.card_name_style p{
    color: var(--banner-desk-fontColor);
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; 
}
.card_name_style {
  color: var(--banner-desk-fontColor);
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; 
}
.card_Text_container{
    margin-left: 2.5vw;
}
.card_space{
  margin-bottom: 2rem;
}
.card_space_em{
  margin-bottom: 1.4rem;
  margin-top: .7rem;
}
.card_heading_space_em{
  margin-bottom: .7rem;
  margin-top: .7rem;
}
.qr_img{
  width: 222px;
  height: 213px;
  display: block;
}
@media (max-width:540px){
  .heading_style{
    text-align: center;
  }
  .card_heading_style{
    font-size: 1rem;
  }
  .card_name_style p{
    font-size: .875rem;
  }
  .card_name_style {
    font-size: .875rem;
  }
  .wrapper {
    /* border: 1px solid red; */

    padding-left: 1.5rem;
    padding-right: 1rem;
  }
  .card_space{
    margin-bottom: 1rem;
  }
  .qr_img{
    display: none;
  }
  .card_flex{
    gap: 0rem;
  }
  .card_space_em{
    margin-bottom: 8px;
  }
  .card_heading_space_em{
    margin-top: 0;
    margin-bottom: 0;
  }
 
}