.login_wrapper {
    width: 90%;
    justify-content: center;
    margin: auto;
    padding: 15px;
    height: 100vh;


}

.parent {
    width: 100%;
    background: transparent linear-gradient(159deg, #E1FFFA 0%, #FFE1F1 50%, #E1FFFA 100%) 0% 0% no-repeat padding-box;
}

.termsCondition {
    color: #008470;
}

.myPhoneInputClass {
    height: 100% !important;
    width: 100% !important;

}

.myContainerClass {
    height: 40px;
}

.myDropdownClass {
    height: 200px;
    

}

.inputWrapper {
    width: 100%;
    /* border: 1px solid red; */
}


/* YourComponent.module.css */
.customModal .chakra-modal__content {
    background-color: #ff0000;
    /* Replace with your desired color */
}


@media(max-width:400px) {
    .myPhoneInputClass {
        width: 100% !important;
    }
}