.heading_Text {
    color: var(--deep-grey);
    font-family: "Poppins" sans-serif;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sub_Text {
    color: var(--deep-grey);
    font-family: "Poppins" sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form_Text {
    color: var(--deep-grey);
    font-family: "Poppins" sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button_Text {
    color: #FFF;
    font-family: "Poppins" sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.28px;
}

.input_box {
    display: flex;
    height: 3.25rem;
    padding: 16px 16px 16px 24px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
}

.input_flex{
    width: 100%;
   display: flex;
   justify-content:"space-between" ;
   gap: 5rem;
           
      
}
.input_width{
    width: 46%;
}

@media(max-width:520px) {
    .heading_Text {

        font-size: 1.25rem;

    }
        .input_flex {
            width: 100%;
            display: flex;
           flex-direction: column;
            gap: 1rem;

    
    
        }
                .input_width {
                    width: 100%;
                }

}