.wrapper {
    margin-top: 1rem;
}
.headingStyle{
    font-family: "Poppins" ;
    font-size: 1.6rem;   
    font-weight: 600;
    
    line-height: 2rem;
}
.text_Style {
    font-family: "Poppins" ;
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: rgb(23, 27, 35)
}