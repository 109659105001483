@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@300&family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&family=Ubuntu:wght@300;400;500&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
}

.leaflet-container {
  height: 60vh;
  width: 80vw;
  display: block;
}


/* html {
  background-color: #eef2f2;
} */