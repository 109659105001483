.redIcon {
  font-size: 40px;
  padding: 10px;
  border-radius: 50px;
  background-color: #cecece;
  color: black;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.redIcon:active {
  background-color: #cecece;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transform: translateY(4px);
}
