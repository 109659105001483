.wrapper {
  /* border: 1px solid rgb(64, 64, 188); */
  width: 100%;
  height: 100%;

  /* //for ref// */
}

.container_parent {
  width: 100%;
  /* border: 1px solid rgb(104, 219, 47); */
  height: 100%;
  background-color:var(--secondary-bacground);
  /* height: 420px; */
  padding-top: 5.938rem;
  
  padding-left: 7vw;
  
}


/* .container {
  margin-top: 9.063rem;
  margin-left: 4.625rem;
  background-color:#eef2f2;
} */
.right_Container{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  /* border: 1px solid red; */
  /* height: 100%; */
  gap: 2rem;
}
.right_parent{
  /* border: 1px solid red; */
  width: 46%;
}
.flex_Container{
 
  display: flex;
  justify-content: space-between;
}

.container2 {
padding-top: 5.938rem;
padding-left: 7vw;
  /* background-color: #eef2f2; */
}
.container3 {
 
    background-color: var(--secondary-bacground);

  }

  .background{
  padding-top: 5.25rem;
  padding-bottom: 5.25rem;
  padding-left: 7vw;
  padding-right: 7vw;
  }

.heading_style{
  text-transform: uppercase;
  color: var(--primary-color);
  font-size: var(--nav-fontSize);
  font-weight: 500;
  line-height: 48px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.sub_head_style{
color:var(--banner-desk-fontColor);
font-family: 'Poppins', sans-serif;
font-size: var(--sub-heading-fontSize);
font-style: normal;
font-weight: 500;
line-height: 36px; 
}

.flex_section3{
  display: flex;
  justify-content: space-between;
  margin-top: 2.56rem;
}
.grid{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 1rem;
}
.grid_Wrapper{
  padding: 3rem;
}

.heading_text {
  color: var(--heading-color);
  font-family: 'Poppins', sans-serif;
  font-size: var(--sub-heading-fontSize);
  font-weight: 500;
  width: 45%;
  /* letter-spacing: 1.28px; */
  /* text-transform: uppercase; */
}

.heading_Top {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: var(--heading-fontSize);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  list-style: 3.25rem;
  color: var(--deep-grey);
  width: 100%;
  border: "1px";
}

.heading_Top2 {
  font-size: var(--heading-fontSize);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  list-style: 3.25rem;
  color: var(--deep-grey);
  /* width: 100%; */
}



.container_parent3 {
  width: 100%;
  border: 0.0px solid rgb(243, 245, 242);
  /* background-color: #eef2f2; */
  height: 100%;
  margin-bottom: 8rem;
}

.grid_System {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: auto;
}

.arcStep_Container {
  margin-top: 10rem;
  margin-bottom: 5rem;
  width: 100%;
}

.tech_Card {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: "center";
  gap: .5rem;
}

.accordion_wrapper {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  width: 100%;
}

/* .video_Screen{
  width: 55%;
  height: 30rem;
  background: "#E1E8EB";
  margin-bottom: "2rem";   
} */
.video_container {
  position: relative;
  width: 55%;
  padding-top: 31.82%;
  /* 16:9 aspect ratio (9 / 16 * 100%) */
  background: #E1E8EB;
  margin-bottom: 2rem;
}

.video_container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.inner_Container{ 
  width: 100%;
  height: 100%;
  padding: 60px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  gap: 1rem;
}
.steps{
    /* border: 1px solid red; */
  width: 50%;
 
 
}
.container4{
  padding-top: 5.25rem;
  /* padding-bottom: 5.25rem; */
  padding-left: 7vw;
  padding-right: 7vw;
  /* border: 2px solid red; */

}
.container5{
  padding-top: 1.25rem;
  /* padding-bottom: 5.25rem; */
  padding-left: 7vw;
  padding-right: 7vw;
  /* border: 2px solid red; */

}
.poco_bridge_width{
  width: 40%;
}
.poco_equ_width{
  width: 40%;
}

.card_flex{
  width: 100%;
  /* height: 100%;
  border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.er_card_width{
  width: 46%;
  /* height: auto; */
}
.em_card_width{
  width: 46%;
  /* height: auto; */

}
.heading_sec3{
  text-align: left;
}

@media (max-width:1000px){
  .heading_text{
    width: 60%;
  }
  .inner_Container{ 
flex-direction: column;
justify-content: space-between;
/* gap: 5rem; */
  }
  .steps{
    width: 100%;
    height: 100%;
   
  }
  .right_parent{
    width: 100%;
    margin-top: 5rem;
  }
  .grid{
    grid-template-columns: repeat(2, 1fr); 
  }
  .grid_Wrapper{
    padding: 1rem;
  }
  .poco_bridge_width{
    width: 46%;
  }
  .poco_equ_width{
    width: 46%;
  }
  .er_card_width{
    width: 49%;
  }
  .em_card_width{
    width: 49%;
  }
  .background{
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
@media(max-width:880px) {
  .container2 {
    margin-top: 20.063rem;

  }
  .card_flex{
 
    display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  }
  .er_card_width{
    width: 80%;
  }
  .em_card_width{
    width: 80%;
  }
 
}

@media(max-width:768px) {
  .video_Screen {
    width: 80%;
    height: 20rem;

  }
.flex_section3{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poco_bridge_width{
  width: 80%;
}
.poco_equ_width{
  width: 80%;
  margin-bottom: 2rem;
}


  .accordion_wrapper {
    margin-top: 2rem;
  }

  .container_parent2 {

    height: 750px;
    margin-top: 10rem;
  }

  .container2 {
    margin-top: 15.063rem;
    margin-left: 1rem;
  }

  .arcStep_Container {
    margin-top: 12rem;
    margin-bottom: 10rem;
    /* border:1px solid red; */
  }
}

@media(max-width:740px) {
  .arcStep_Container {
    margin-top: 2rem;
    /* border:1px solid red; */
  }

  .tech_Card {
    flex-direction: column;
    gap: 1rem;
  }

  .container_parent2 {
    height: 100%;
  }
}

@media(max-width:550px) {
  .container_parent{
    margin-left: 0;
  }
  .inner_Container{
    padding: 1.5rem;
  }
  .heading_text{
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
  .container2 {
    margin-top: 30rem;
    margin-left: 1rem;

  }
  .heading_sec3{
    text-align: center;
  }
  .er_card_width{
    width: 95%;
  }
  .em_card_width{
    width: 95%;
  }
  .video_Screen {

    margin: auto;
    width: 95%;
    height: 15rem;


  }
  .heading_style{

    font-size: .875rem;
  
  }
    .sub_head_style{
    font-size: 1.25rem;
    text-align: center;
    
    }
  .video_container {
    width: 80%;
    padding-top: 5%;
    min-height: 15rem;
    /* Adjust the minimum height for smaller screens */
  }

  .heading_Top {
    font-size: 1.5rem;
  }

  .heading_Top2 {
    font-size: 1.5rem;
  }


  .grid_System {
    grid-template-columns: repeat(1, 1fr);
  }

  .arcStep_Container {
    margin-top: 3rem;
    /* border:1px solid red; */
  }
  .grid_Wrapper{
    padding: .5rem;
  }
  .grid{
    grid-template-columns: repeat(1, 1fr); 
  }
  
}


@media(max-width:490px) {
  .container2 {
    margin-top: 22rem;
    /* background-color: red; */
  }

  .video_container {
    width: 98%;
    padding-top: 0%;
    /* 16:9 aspect ratio (9 / 16 * 100%) */
    margin-bottom: 1rem;
  }


}

@media(max-width:350px) {
  .video_container {
    width: 98%;
    padding-top: 0%;
    min-height: 13rem;
    /* Adjust the minimum height for smaller screens */
  }



}
@media(max-width:2500px) {
  .container2 {
    margin-top: 1.063rem;

  }
}