.circleProgress {
    position: absolute;
    top: 50%;
    left: 138px;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    /* background-color: #008470; */
    color: white;

    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 16px
}