.wrapper {
    background-color: white;
    height: 100%;
    padding-bottom: 5rem;

}

.container {
    width: 50%;
    margin: auto;
    padding-top: 5rem;
    padding-bottom: 1rem;
}

.primary_Color {
    /* color: var(--primary-color); */
}

.text_Style {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    line-height: 2.3rem;
    color: rgb(23, 27, 35);
    font-family: "Poppins";
    
}

@media(max-width:768px) {
    .container {
        width: 80%;

    }

    .text_Style {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

@media (max-width:450px) {
    .container {
        width: 90%;

    }
}