.profile_wrapper {
    /* height: 100vh; */
    width: 80%;
    margin: auto;
    margin-top: 20px;
    background: none;
    margin-bottom: 20px;
    margin-top: 5rem;
}

.profile_sub {
    display: flex;
    justify-content: space-between;
    /* height: 100vh; */
}

.profile_left {
    width: 25%;
    position: relative;
    background: #f5f9f9;
    border-radius: 30px;
    /* border: 2px solid green; */
    top: 2%;
}

.profile_left_container {
    display: flex;
    position: fixed;
    top: 0;
    left: 20;
    /* overflow-y: scroll; */
    justify-content: center;
    align-items: center;
    top: 20%;
}

.profile_right {
    position: relative;
    width: 70%;
    /* border: 2px solid rgb(0, 15, 128); */

    /* height: 100%; */
}

.left_list {
    cursor: pointer;
}

.left_list li {
    color: #1c2b2f;
    font-size: 1rem;
    font-weight: 500;
}

.ben_PersonalDetails {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;
    width: auto;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.personalDetails_Top {
    /* border: 1px solid green; */
    /* height: 50%; */
    /* padding: 10px; */
}

.personalDetails_Bottom .benAddressDetails {
    /* border: 2px solid rgb(128, 0, 105); */

    margin-top: 0.75rem;
}

.personDetails_Child {
    display: flex;
    justify-content: space-evenly;
    /* border: 3px solid red; */
}

.personDetails_left {
    width: 50%;
    /* border: 3px solid rgb(8, 0, 255); */
    display: flex;
    flex-direction: column;
}

.personDetails_right {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    /* border: 3px solid rgb(8, 0, 255); */
}

.personDetails_right_img {
    width: 115px;
    border-radius: 50%;
    height: 115px;
    /* border: 1px solid red; */
}

.personDetails_right_img img {
    height: 100%;
    border-radius: 50%;
}

.personalDetails_RightDet {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.benName {
    color: #1c2b2f;
    font-weight: 600;
}

.relationToBen {
    color: #6f6f6f;
}

.requestDetail {
    background: #eff3f3 0% 0% no-repeat padding-box;
    border-radius: 40px;
    padding: 8px;
    opacity: 1;
    color: #008470;
    font-weight: 500;
    position: relative;
}

.customercareImage {
    width: 36px;
    height: 36px;
    background: #eff3f3 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customercareImage img {
    width: 20px;
    height: 20px;
}

.ambulanceImage {
    width: 36px;
    height: 36px;
    background: #fcd3d0 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ambulanceImage img {
    width: 20px;
    height: 20px;
}

.edit {
    position: absolute;
    top: 20px;
    right: 10px;
}

.topColor {
    position: absolute;
    background: transparent linear-gradient(98deg, #e1fffa 0%, #ffe1f1 50%, #e1fffa 100%) 0% 0% no-repeat padding-box;
    height: 100px;
    top: 0;
    width: 100%;
    z-index: -1;
}

.profileDetails_Bot_child {
    display: flex;
    justify-content: space-evenly;
    /* border: 1px solid red; */
}

.subTitle {
    font-size: 1.1rem;
    font-weight: 500;
}

.profileDetails_Bot_left {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.profileDetails_Bot_right {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}

.contactDetails_Right_det {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
}

.contact_Number {
    color: #1c2b2f;
}

.pendingDetails {
    display: flex;
    margin-top: 20px;
    /* gap: 100px; */
    padding-left: 13px;
    justify-content: space-between;
    align-items: flex-start;
    /* border: 1px solid red; */
}

.pendingDetail_Title {
    color: #6f6f6f;
    font-weight: 600;
}

.penDetails_det {
    color: #1c2b2f;
    font-size: 18px;
    font-weight: 450;
}

.active {
    color: #008470;
}

@media (max-width: 785px) {
    .profile_left {
        display: none;
    }

    .profile_right {
        position: relative;
        width: 100%;
        /* border: 2px solid rgb(0, 15, 128); */
    }
}

@media (max-width: 768px) {
    .contactDetails_Right_det {
        display: block;
        gap: 5px;
    }
}

@media (max-width: 625px) {
    .contactDetails_Right_det {
        display: block;
    }
}

@media (max-width: 600px) {
    .profileDetails_Bot_child {
        display: flex;
        padding-left: 5px;
        gap: 5px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .profileDetails_Bot_right {
        display: block;
    }

    .profileDetails_Bot_left {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .pendingDetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 5px;
        padding-left: 5px;
    }

    .requestDetail::before {
        content: "Rd";
    }

    .requestDetail {
        overflow: hidden;
        text-indent: -100px;
        white-space: nowrap;
    }
}