.address_Wrapper {
    /* height: 250px; */
    /* border: 1px solid red; */
    position: relative;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 20px;

}

.subTitle {

    font-size: 1.1rem;
    font-weight: 600;
    color: #008470;

}

.address_container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    /* border: 1px solid red */
}

.typeAddress {
    color: #6F6F6F
}

.addressDetails {
    color: #1C2B2F;
    font-size: 1rem;
    font-weight: 500;

}

.editIcon {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;

}

.hospitalAddress {
    color: #1C2B2F;
    font: 1rem;
}

.addAddress {
    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.addressCard {
    display: flex;
    align-items: center;
}

.additionalDetails {
    color: var(--primary-color);
    font-weight: 500;
    margin-top: .875rem;

}

.addDetails {
    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.detailsCard {
    display: flex;
    align-items: center;
}

.address_card_container {
    margin-top: 1rem;
    padding-right: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.card {
    box-shadow: -1px 0.785886px 2.14355px rgba(0, 0, 0, 0.112);
    padding: 1rem;
    border-radius: 1rem;
}

.flex {
    display: flex;
    align-items: center;
    gap: .3rem;
}

.gridSystem {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
}

.checkbox {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

@media (max-width: 610px) {
    .address_card_container {

        grid-template-columns: repeat(1, 1fr);

    }
}